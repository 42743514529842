import md5 from 'js-md5'
export var SearchList = [
  { labe: '需求单号', code: 'demandOrderCode', type: 'input' },
  {
    labe: '期望送达日期',
    code: 'sdData',
    type: 'daterange'
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '待确认', value: '1' },
      { label: '已确认', value: '2' },
      { label: '已签收', value: '3' }
    ]
  },
  {
    labe: '下发日期',
    code: 'xfData',
    type: 'daterange'
  }
]
export var SearchData = {
  demandOrderCode: '',
  sdData: '',
  status: '',
  xfData: ''
}

export var tableField = [
  { label: '需求单号', code: 'demandCode', type: 'input', width: '' },
  { label: '期望送货日期', code: 'demandDate', type: 'input', width: '' },
  { label: '送货地址', code: 'baseName', type: 'input', width: '' },
  { label: '联系人', code: 'lxr', type: 'input', width: '' },
  { label: '联系方式', code: 'lxFs', type: 'input', width: '' },
  { label: '需求下发时间', code: 'issueTime', type: 'input', width: '' },
  { label: '采购员', code: 'buyerName', type: 'input', width: '' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 1 ? '待采购员确认' : data.status === 2 ? '待确认' : data.status === 3 ? '已确认' : data.status === 4 ? '已签收' : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '50px',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
      // {
      //   actionLabel: '确认',
      //   operationButton: 'confirm',
      //   stateField: 'status',
      //   stateSymbol: '=',
      //   stateValue: '1'
      // },
      // {
      //   actionLabel: '上传签收单',
      //   operationButton: 'up',
      //   stateField: 'status',
      //   stateSymbol: '=',
      //   stateValue: '2'
      // }
    ]
  }
]

export var tableData = []

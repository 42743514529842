<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
    <!-- 上传签收单弹框 -->
    <div class="dialogBox">
      <el-dialog top="0px" title="上传签收单" v-model="dialogModify" width="40%">
        <div class="content">
          <div class="mainform">
            <div class="form">
              <el-form :model="endTimeData" ref="endTimeData" label-width="110px" class="demo-ruleForm">
                <el-form-item label="签收日期" prop="endTime">
                  <el-date-picker type="datetime" @change="timeForm" placeholder="选择日期" v-model="endTimeData.endTime" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="签收单" prop="sn">
                  <el-upload
                    ref="upload"
                    name="file"
                    :auto-upload="true"
                    :action= 'postUrl'
                    :on-exceed="handleExceed"
                    :on-change="handleChansge"
                    :on-success="handSuccess"
                    :on-error="handError"
                    :headers="tokenHeader"
                  >
                    <el-button size="mini" type="primary" plain>+上传</el-button>
                  </el-upload>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button size="mini" @click="dialogModify = false">取 消</el-button>
            <el-button size="mini" type="primary">确 定</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { baseURL, request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'HairSupplierDemand',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      dialogModify: false,
      endTimeData: {
        endTime: ''
      },
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/bid/upload/material',
      tableField: tableField,
      tableData: tableData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },

  methods: {
    // 获取供应商交付-直发送货需求列表数据
    myBidData () {
      let sdksTime = ''
      let sdjsTime = ''
      if (this.childData.searchData.sdData) {
        sdksTime = dayjs(this.childData.searchData.sdData[0]).format('YYYY-MM-DD')
        sdjsTime = dayjs(this.childData.searchData.sdData[1]).format('YYYY-MM-DD')
      }
      let xfksTime = ''
      let xfjsTime = ''
      if (this.childData.searchData.xfData) {
        xfksTime = dayjs(this.childData.searchData.xfData[0]).format('YYYY-MM-DD')
        xfjsTime = dayjs(this.childData.searchData.xfData[1]).format('YYYY-MM-DD')
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        ...this.childData.searchData,
        sdksTime: sdksTime,
        sdjsTime: sdjsTime,
        releaseBeginTime: xfksTime,
        releaseEndTime: xfjsTime,
        confirmFlag: 1
      }
      request('/api/livraison/direct/getLivraisonDirectDataListByUserInfo', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'HairSupplierDetails' }, query: { demandCode: data.demandCode } })
      }
    },
    // 选择报价截止日期转换格式
    timeForm (val) {
      if (this.endTimeData.endTime) {
        this.endTimeData.endTime = dayjs(this.endTimeData.endTime).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.endTimeData.endTime = ''
      }
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
@import '@/assets/css/elform.scss';
@import '@/assets/css/dialogBox.scss';
</style>
